:where(.css-42nv3w).ant-menu-dark .ant-menu-item-selected, :where(.css-42nv3w).ant-menu-dark>.ant-menu .ant-menu-item-selected {
    background-color:rgb(255, 255, 255);
    font-size: medium;
    color:black;
    border-radius: 0;
    width: 100%;
    margin-left: 0px;
    font-family: 'Condiment - Regular';
    font-weight: bold;


}

.ant-menu-item{
    font-family: 'Condiment - Regular';
    font-size: medium;
}

.ant-avatar .ant-avatar-circle .ant-avatar-image .css-42nv3w{
    width: 70px;
    height: 87px;

}
.ant-input-search :where(.css-42nv3w).ant-input-group .ant-input-affix-wrapper:not(:last-child){
    border:1px solid #acacac;
}

:where(.css-42nv3w).ant-input-search >.ant-input-group >.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    color: rgb(255, 255, 255);
    border:none;
    background-color: rgb(0 150 135);
    box-shadow :0px 2px 5px rgba(0, 0, 0, 0.1);
    font-family: 'Condiment - Regular';

    
}

:where(.css-42nv3w).ant-btn.ant-btn-icon-only .anticon {
    font-size: 16px;
    color: rgb(81, 80, 80);
    
}
:where(.css-42nv3w).ant-layout.ant-layout-has-sider >.ant-layout-content{
    margin: 82px 220px;
    padding: 23px;
    min-height: 92%;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    position: absolute;
    width: 100%;
}

:where(.css-42nv3w).ant-layout .ant-layout-sider-children {
    height: 100vh;
    margin-top: -0.1px;
    padding-top: 0.1px;
}

.anticon svg {
    display: inline-block;
    fill: rgb(255, 255, 255);
}

:where(.css-42nv3w).ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    color: #ffffff;
    border-color: #ffffff;
}

:where(.css-42nv3w).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: rgb(255, 255, 255);
    border-color: rgb(255, 255, 255);
    color: #000000;

}

.ant-btn .css-42nv3w .ant-btn-default{
 margin-top: 30px;
}

.logoutButton {
    justify-self: end;
    margin-top: 40px;
    padding-left: 19px;
    margin-left: 25px;
    width: 70%;
    border-radius: 9px;
    display: flex;
    align-items:center;
    gap: 10px;
    border: none;
    font-family: 'Condiment - bold';
    border: none;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);



}

:where(.css-42nv3w).ant-message .ant-message-notice-wrapper .ant-message-notice-content {
    display: inline-block;
    padding: 9px 12px;
    color: rgb(0, 0, 0);
    background: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: all;
    border: none;
    font-family: 'Condiment - Regular';

}
    @media only screen and (max-width: 768px) {
    :where(.css-42nv3w).ant-avatar .ant-avatar-circle .ant-avatar-image .css-42nv3w {
      width: 50px; 
      height: 50px; 
    }
  }

  :where(.css-42nv3w).ant-layout-header{
    height: 64px;
    padding: 2 50px;
    line-height: 64px;
    background: #ffffff;
  } 

:where(.css-42nv3w).ant-layout .ant-layout-header, :where(.css-42nv3w).ant-layout.ant-layout-footer{
    height: 64px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.88);
    line-height: 64px;
} 

:where(.css-42nv3w).ant-layout-header {
    height: 64px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.88);
    line-height: 64px;
} 

 :where(.css-42nv3w).ant-layout-header {
    height: 64px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.88);
    line-height: 64px;
    background-color: white;
}

:where(.css-42nv3w).ant-layout .ant-layout-header, :where(.css-42nv3w).ant-layout.ant-layout-footer {
    flex: 0 0 auto;
    margin-left: -1%;
}

:where(.css-42nv3w).ant-layout [class^="ant-layout"], :where(.css-42nv3w).ant-layout [class*=" ant-layout"] {
    box-sizing: border-box;
}


:where(.css-42nv3w) a {
    color: #000000;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    margin-left:6px ;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
}

:where(.css-42nv3w).ant-layout-header {
    height: 64px;
    padding: 0px auto;
    margin-left: -6px;
    color: rgba(0, 0, 0, 0.88);
    line-height: 64px;
    background: #ffffff;
}

:where(.css-42nv3w).ant-menu-dark .ant-menu-item, :where(.css-42nv3w).ant-menu-dark>.ant-menu .ant-menu-item, :where(.css-42nv3w).ant-menu-dark .ant-menu-submenu-title, :where(.css-42nv3w).ant-menu-dark>.ant-menu .ant-menu-submenu-title {
    color:gainsboro;
}
.anticon svg {
    display: inline-block;
    fill: rgb(1 1 1);
}

:where(.css-42nv3w).ant-menu-dark .ant-menu-item-selected, :where(.css-42nv3w).ant-menu-dark>.ant-menu .ant-menu-item-selected {
    background-color: #ffffff;
}

:where(.css-42nv3w).ant-menu-dark .ant-menu-item, :where(.css-42nv3w).ant-menu-dark>.ant-menu .ant-menu-item, :where(.css-42nv3w).ant-menu-dark .ant-menu-submenu-title, :where(.css-42nv3w).ant-menu-dark>.ant-menu .ant-menu-submenu-title {
    color: #222222;
}
:where(.css-42nv3w).ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    color: rgb(30, 132, 126);
    border-color: #000000;
    background: #ffffff;
}

:where(.css-42nv3w).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: rgb(30, 132, 126);
    border-color: rgb(30, 132, 126);
    font-size: large;
    background: #ffffff;
}


.ant-layout-content.css-42nv3w{
position:relative;
padding: 13px 48px;
margin: 50px 220px;
width: 66vw;
}

:where(.css-42nv3w).ant-menu {
    box-sizing: border-box;
    margin: 57px auto;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 0;
    list-style: none;
    font-family:'Condiment - Regular';
    margin-bottom: 0;
    padding-inline-start: 0;
    outline: none;
    transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}

span.anticon.anticon-bars{
    display: none;
}
