strong{
    font-weight: 100;
    font-size: large;
}

li{
    font-weight: 100;
    font-size: large;
}

ul{
    font-weight: 100;
    font-size: large;
}