:where(.css-42nv3w).ant-btn-primary {
    color: #fff;
    background: rgb(30, 132, 126);
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

:where(.css-42nv3w).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active {
    color: #fff;
    background:rgb(30, 132, 126) ;
}

:where(.css-42nv3w).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    background:rgb(30, 132, 126);
}
